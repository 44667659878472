


















import { Vue, Component } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { rolesTranslation } from '@/config/types/roles'
import UserAPI from '@/api/requests/user'
import { userMapper } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['userRoles'])
  }
})

@Component
export default class ChangeRole extends Mappers {
  model: any = {
    roles: []
  }

  private schema: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        label: 'Роли',
        model: 'roles',
        styleClasses: 'w-100',
        options: Object.values(rolesTranslation),
        multiple: true
      }
    ]
  }

  private async created() {
    this.model.roles = this.userRoles.map(
      (role: string) => rolesTranslation[role]
    )
  }

  private sendUser() {
    const postRoles: any = this.model.roles.map((role: string) =>
      Object.keys(rolesTranslation).find(key => rolesTranslation[key] === role)
    )
    UserAPI.loginRedirect(
      postRoles && postRoles.length ? postRoles : [],
      '/auth/dev?'
    )
  }
}
